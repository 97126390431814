.screenFilter {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.3);
}

.sortableUI {
  .dragAndDropContainer {
    position: absolute;
    z-index: 100;
    width: 300px;
    padding: 10px;
    left: auto;
    right: 0px;
    border: solid 1px #007ac3;
    .draggable {
      text-align: center;
      display: inline;
      margin-left: 5px;
      margin-right: 30px;
      .form-check-input {
        margin-top: 5px;
        margin-right: 3px;
      }
    }
  }
}
