$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.custom-control-label {
  margin-bottom: 0;
  padding-left: 15px;
  padding-top: 8px;
}

.custom-checkbox {
  margin-bottom: 0.8rem;
  .custom-control-label::before {
    height: 1.75rem;
    width: 1.75rem;
    border: 1px solid #6c93ba;
  }
  .custom-control-input {
    height: 28px !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #6b92b9;
    border: 1px solid #6c93ba;
    width: 1.75rem;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28.412 22.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%23ffffff' stroke='%23ffffff' stroke-width='3'/%3e%3c/svg%3e ");
  }
  .custom-control-label::after {
    top: 0.25rem;
    left: -1.5rem;
    height: 1.75rem;
    width: 1.75rem;
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
    border: 1px solid #6b92b9;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='22' viewBox='0 0 28.412 22.855'%3e%3cpath id='check' d='M22.6,3.8,8,19.4,1.4,11.7,0,12.9l7.9,9.3L24,5.1Z' transform='translate(0.703 -3.095)' fill='%239ca0b1' stroke='%239ca0b1' stroke-width='3'/%3e%3c/svg%3e ");
  }
}
